import Vue from 'vue';
import App from '../App.vue';
import VueResource from 'vue-resource';
import Vuetify from 'vuetify';
import Vuex from 'vuex'

import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(VueResource);
Vue.use(Vuetify);
Vue.use(Vuex);

let allowRefresh = true;

Vue.http.interceptors.push(function(request, next) {
  let token = localStorage.token;
  let refresh_token = localStorage.refresh_token;

  request.headers.set('X-CSRF-TOKEN', document.querySelectorAll('meta[name="csrf-token"]')[0].content);
  request.headers.set('X-REFRESH-TOKEN', refresh_token);

  if (token) {
    request.headers.set('Authorization', `Bearer ${token}`);
    next(response => {
      if (allowRefresh && response.status === 401) {
        allowRefresh = false;
        return this.$http.post('/web/refresh')
            .then(response => {
              console.log(response);
              allowRefresh = true;
              this.$store.commit('refreshToken', {token: response.body.data.access});
              request.headers.set('Authorization', `Bearer ${response.body.data.access}`);
              return Vue.http(request).then(data => {
                return data
              })
            })
            .catch((response) => {
              console.log(response);
              store.commit('logoutUser');
              this.$store.commit('updateMessage', { active: true, message: 'Session expired' })
        })
      }
    })
  }

});

const store = new Vuex.Store({
  state: {
    role: null,
    show_message: false,
    message: '',
    message_type: '',
    message_position: '',
  },
  mutations: {
    loginUser (state, payload) {
      localStorage.setItem("token", payload.token);
      localStorage.setItem("refresh_token", payload.refresh_token);
      localStorage.setItem("role", payload.role);
      state.role = payload.role;
      state.show_message = true;
      state.message = 'You are now signed in.';
      state.message_type = 'info';
      state.message_position = 'top';
    },
    logoutUser (state) {
      delete localStorage.token;
      delete localStorage.refresh_token;
      delete localStorage.role;
      state.role = null;
    },
    refreshToken(state, payload) {
      localStorage.setItem("token", payload.token);
    },
    initialiseStore(state) {
      if(localStorage.getItem('role')) {
        state.role = localStorage.getItem('role');
      }
    },
    updateMessage(state, payload) {
      state.show_message = payload.active;
      state.message = payload.message;
      if (payload.message_type) {
        state.message_type = payload.message_type;
      } else {
        state.message_type = 'info';
      }
      if (payload.message_position) {
        state.message_position = payload.message_position
      } else {
        state.message_position = 'top'
      }
    }
  },
  getters: {
    role: state => {
      return state.role;
    },
    show_message: state => {
      return state.show_message;
    },
    message: state => {
      return state.message;
    },
    message_type: state => {
      return state.message_type;
    },
    message_position: state => {
      return state.message_position;
    }
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('application'));
  const app = new Vue({
    vuetify : new Vuetify(),
    store: store,
    el,
    render: h => h(App),
  })

});
