<template>
  <div id="app">
    <v-app>
      <v-app-bar app>
        <div class="mr-5">
        <a href="https://www.caltech.edu/"><img src="https://covid-atmos-data-links.caltech.edu/images/caltech.png" class="caltech_logo"></a> 
        <a href="https://www.kiss.caltech.edu/"><img src="https://covid-atmos-data-links.caltech.edu/images/kiss.png" class="kiss_logo"></a> 
        <!--<span class="website_name">Covid-Atmos-Data-Links Filter Application</span>-->
        </div>

	<div class="navigation">
	
        <div class="mr-3 link-block " @click="state = 'main' ">
          <v-icon>home</v-icon>
          Home
        </div>
        
        <div class="mr-3 link-block ">
          <a href="https://covid-atmos-data-links.caltech.edu/assets/about.html">
          <v-icon>info</v-icon>
          About
          </a>
        </div>
        
        <div class="mr-3 link-block ">
          <a href="https://forms.gle/GAREeXkQDuVgg6jZA">
          <v-icon>input</v-icon>
          Submit
          </a>
        </div>
        
                
        <div class="mr-3 link-block ">
          <a href="https://github.com/joshua-laughner/CAADA">
          <v-icon>folder</v-icon>
          Package 
          </a>
        </div>
        
        <div class="mr-3 link-block ">
          <a href="https://forms.gle/AMZSRjU2ojc1CGuf9">
          <v-icon>chat</v-icon>
          Feedback
          </a>
        </div>


        <template v-if="role">
          <div class="mr-3 link-block " @click="logout">
            <v-icon>mdi-account-key</v-icon>
            Logout
          </div>
        </template>
        <template v-else>
          <div class="mr-3 link-block" @click="state = 'login' ">
            <v-icon>mdi-account-key</v-icon>
            Login
          </div>
        </template>
        
        </div>

        <v-alert class="some" :type="message_type" v-if="show_message && message_position === 'top' ">
          {{message}}
        </v-alert>

      </v-app-bar>

      <v-content>

        <template v-if="state === 'login'">
          <v-row
              align="center"
              justify="center"
              class="mt-5"
          >
            <v-col
                cols="12"
                sm="8"
                md="4"
            >
              <v-card class="elevation-2">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Login</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-form
                      ref="loginForm"
                      v-model="valid"
                      lazy-validation
                  >
                    <v-text-field
                        v-model="email"
                        required
                        :rules="emailRules"
                        prepend-icon="email"
                        name="login"
                        label="Email"
                        type="text"
                        autocomplete="off"
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        required
                        :rules="passwordRules"
                        prepend-icon="lock"
                        name="password"
                        label="Password"
                        id="password"
                        type="password"
                        autocomplete="off"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      @click="login()"
                      :disabled="!valid"
                  >
                    Login
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>


        </template>

        <template v-if="state === 'main'">

          <v-container fluid>

            <!-- Filters -->
            <v-row>
              <v-col class="text-center application" cols="12">
                <v-row>
                  <v-col cols="12" lg="2" class="categories">
                    <template v-for="c in categories">
                      <v-checkbox class="mt-0 checkbox-white" v-model="filterCategory" :label="c.name" :value="c.id"></v-checkbox>
                    </template>

                  </v-col>
                  
                  


                  <v-col cols="12" lg="10" class="filters">
                  
                  <div class="web-title">
                  <span class="website_name">Covid-Atmos-Data-Links Filter Application</span>
                  </div>
                  
                  
                  <div class="spatial_resolutions">
                    <h3 class="subtitle-1">Spatial Resolutions</h3>
                    <v-range-slider
                        class="spatial_slider"
                        v-if="spatialResolutions && spatialResolutions.length > 0"
                        :tick-labels="spatialResolutions.map(a => ['8', '64', '192', '768', '1536', '3072'].includes(a.attributes.name) ? '' : a.attributes.name)"
                        v-model="filterSpatialResolution"
                        :min="0"
                        :max="spatialResolutions.length - 1"
                        ticks="always"
                        tick-size="5"
                    >
                    </v-range-slider>
		     </div>
		     
		     <div class="spatial_coverage">
                    <h3 class="subtitle-1">Spatial Coverage</h3>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-select
                            v-if="regions"
                            :items="regions.map(function(c) { return { text: c.attributes.name, value: c.id } })"
                            label="Region"
                            v-model="filterRegion"
                            solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                            v-if="countries && filterRegion"
                            :items="countries.filter(country => country.attributes.region_id === parseInt(filterRegion)).map(function(c) { return { text: c.attributes.name, value: c.id } })"
                            label="Country"
                            v-model="filterCountry"
                            solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                            v-if="states && filterCountry && states.filter(state => state.attributes.country_id == filterCountry).length > 0"
                            :items="states.filter(state => state.attributes.country_id == filterCountry).map(function(c) { return { text: c.attributes.name, value: c.id } })"
                            label="State"
                            v-model="filterState"
                            solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                            v-if="counties && filterState && counties.filter(county => county.attributes.state_id == filterState).length > 0"
                            :items="counties.filter(county => county.attributes.state_id == filterState).map(function(c) { return { text: c.attributes.name, value: c.id } })"
                            label="County"
                            v-model="filterCounty"
                            solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                            v-if="filterCounty && filterCities.length > 0"
                            :items="filterCities.map(function(c) { return { text: c.attributes.name, value: c.id } })"
                            label="City"
                            v-model="filterCity"
                            solo
                        ></v-select>
                      </v-col>
                    </v-row>
                    </div>
                    
                    <div class="temporal_resolution">
                    <h3 class="subtitle-1">Temporal Resolutions</h3>
                    <v-range-slider
                        :tick-labels="temporalResolutions.map(a => a.text)"
                        v-model="filterTemporalResolution"
                        min="0"
                        :max="7"
                        ticks="always"
                        tick-size="5"
                    >
                    </v-range-slider>
                    </div>
                    
                    <div class="temporal_coverage">
                    <h3 class="subtitle-1">Temporal Coverage</h3>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-menu
                            ref="menu_first"
                            v-model="menu_first_date"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filterTemporalCoverageStart"
                                label="From"
                                prepend-icon="event"
                                readonly
                                clearable
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="filterTemporalCoverageStart" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu_first_date = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.menu_first.save(date)">OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" md="6">
                        <v-menu
                            ref="menu_second"
                            v-model="menu_second_date"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filterTemporalCoverageEnd"
                                label="To"
                                prepend-icon="event"
                                readonly
                                clearable
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="filterTemporalCoverageEnd" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu_second_date = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.menu_second.save(date)">OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    </div>

                    
                    <div class="keywords">
                    <h3 class="subtitle-1">Keywords</h3>

                    <v-row>
                      <v-col cols="12" lg="5">
                        <v-text-field
                            label="( Keyword"
                            v-model="filterKeywordFirst"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <v-select
                            :items="[{text: 'OR', value: 'or'}, {text: 'AND', value: 'and'}]"
                            label="Condition"
                            v-model="filterKeywordCondition"
                            solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="5">
                        <v-text-field
                            label="Keyword )"
                            v-model="filterKeywordSecond"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center" justify="center">
                      <v-col cols="1">
                        <v-switch
                            v-model="filterKeywordBlockCondition"
                            :label="filterKeywordBlockCondition"
                            false-value="AND"
                            true-value="OR"
                        ></v-switch>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" lg="5">
                        <v-text-field
                            label="( Keyword"
                            v-model="filterKeywordThird"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <v-select
                            :items="[{text: 'OR', value: 'or'}, {text: 'AND', value: 'and'}]"
                            label="Condition"
                            v-model="filterKeywordSecondCondition"
                            solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="5">
                        <v-text-field
                            label="Keyword )"
                            v-model="filterKeywordFourth"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    </div>
                    
                    
		<div class="filter_buttons">
                <v-btn @click="getFilteredEntries()" small color="primary">Apply filters</v-btn>
                <v-btn @click="clearFilters()" small color="error">Clear filters</v-btn>
                </div>
                  </v-col>
                  
                </v-row>
		
              </v-col>
            </v-row>
            

            <!-- Data -->
            <v-data-table
                :headers="headers"
                :items="entries"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
                no-data-text="Entries not found"
                no-results-text="Entries not found"
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td> {{ item.attributes.category }} </td>
                  <td> {{ item.attributes.dataset_name }} </td>
                  <td> <a :href="'//' + item.attributes.link">{{ item.attributes.link }}</a>  </td>
                  <td>{{ item.attributes.spatial_resolution.name }}</td>
                  <td> {{ item.attributes.spatial_coverage }} </td>
                  <td>{{ item.attributes.temporal_resolution}}</td>
                  <td>{{ item.attributes.temporal_coverage }}</td>
                  <td>{{ item.attributes.keywords }}</td>
                  <td>
                    <a v-if="item.attributes.internal_link"
                       :href="'//' + item.attributes.internal_link"
                    >
                      {{ item.attributes.internal_link }}
                    </a>
                    <a
                        v-if="item.attributes.file"
                        :href="item.attributes.file.url"
                        :download="item.attributes.file.name"
                    >
                      {{ item.attributes.file.name }}
                    </a>

                  </td>
                  <td>{{item.attributes.data_type ? item.attributes.data_type.name : ''}}</td>
                  <td>
                    <template v-if="item.attributes.data_type && item.attributes.data_type.special1_meaning && item.attributes.special1">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                              v-bind="attrs"
                              v-on="on"
                          >
                            {{item.attributes.special1}}
                          </v-chip>
                        </template>
                        <span>{{item.attributes.data_type.special1_meaning}}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      {{item.attributes.special1}}
                    </template>
                  </td>
                  <td>
                    <template v-if="item.attributes.data_type && item.attributes.data_type.special2_meaning && item.attributes.special2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                          >
                            {{item.attributes.special2}}
                          </v-chip>
                        </template>
                        <span>{{item.attributes.data_type.special2_meaning}}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      {{item.attributes.special2}}
                    </template>
                  </td>
                  <td>
                    <template v-if="item.attributes.data_type && item.attributes.data_type.special3_meaning && item.attributes.special3">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                          >
                            {{item.attributes.special3}}
                          </v-chip>
                        </template>
                        <span>{{item.attributes.data_type.special3_meaning}}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      {{item.attributes.special3}}
                    </template>
                  </td>
                  <td>
                    {{item.attributes.lag}}
                  </td>
                  <td>
                    <v-icon
                        v-if="role"
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <template v-if="role && role === 'admin'">
                      <v-icon
                          v-if="item.attributes.published === true"
                          small
                          class="mr-2"
                          @click="publishItem(item, false)"
                      >
                        mdi-eye
                      </v-icon>
                      <v-icon
                          v-if="item.attributes.published === false || item.attributes.published === null"
                          small
                          class="mr-2"
                          @click="publishItem(item, true)"
                      >
                        mdi-eye-off
                      </v-icon>
                      <v-icon
                          v-if="role"
                          small
                          class="mr-2"
                          @click="copyItem(item)"
                      >
                        mdi-content-copy
                      </v-icon>
                      <v-icon
                          v-if="role"
                          small
                          class="mr-2"
                          @click="destroyItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </td>
                </tr>
                <tr v-if="items.length < 1"><td></td><td></td><td></td><td>
                  No matching entries found
                </td></tr>
                </tbody>
              </template>
            </v-data-table>

            <!-- Form -->
            <v-row align="center">
              <v-col class="text-center" cols="12">
                <v-dialog v-model="dialog" persistent max-width="600px">
                  <template v-slot:activator="{ on }" v-if="role">
                    <v-btn small v-on="on">Add new entry</v-btn>
                    <button data-v-2bc85b69="" type="button" class="v-btn v-btn--contained theme--light v-size--small"><span class="v-btn__content">Clear filters</span></button>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{formTitle}}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-alert class="form_alert" :type="message_type" v-if="show_message && message_position === 'modal' ">
                          {{message}}
                        </v-alert>

                        <v-form
                            ref="createForm"
                            v-model="validCreate"
                            lazy-validation
                        >
                          <v-row>

                            <!-- Categories -->
                            <v-col cols="12" lg="6">
                              <v-select
                                  v-if="categories"
                                  :items="categories.map(function(c) { return { text: c.name, value: c.id } })"
                                  label="Select Category *"
                                  v-model="editedEntry.category"
                                  required
                                  :rules="blankRules"
                                  solo
                              ></v-select>
                            </v-col>
                            <!-- Datatype -->
                            <v-col cols="12" lg="6">
                              <v-select
                                      v-if="dataTypes"
                                      :items="dataTypes.map(function(c) { return { text: c.attributes.name, value: c.id } })"
                                      label="Select Data type"
                                      v-model="editedEntry.dataType"
                                      solo
                              ></v-select>
                            </v-col>
                            <!-- Name -->
                            <v-col cols="12" lg="6">
                              <v-text-field
                                  label="Dataset name *"
                                  v-model="editedEntry.datasetName"
                                  required
                                  :rules="blankRules"
                              ></v-text-field>
                            </v-col>
                            <!-- Link -->
                            <v-col cols="12" lg="6">
                              <v-text-field
                                  label="Link *"
                                  v-model="editedEntry.link"
                                  required
                                  :rules="blankRules"
                              ></v-text-field>
                            </v-col>
                            <!-- Spatial resolution -->
                            <v-col cols="12" lg="6">
                              <v-select
                                  :items="spatialResolutions.map(function(sp) { return {text: sp.attributes.name, value: sp.id} })"
                                  label="Spatial resolution *"
                                  v-model="editedEntry.spatialResolution"
                                  required
                                  :rules="blankRules"
                              ></v-select>
                            </v-col>
                            <!-- Temporal resolution -->
                            <v-col cols="12" lg="6">
                              <v-text-field
                                  label="Temporal resolution"
                                  v-model="editedEntry.temporalResolution"
                              ></v-text-field>
                            </v-col>
                            <!-- Regions -->
                            <v-col cols="12" lg="4">
                              <v-select
                                  v-if="regions"
                                  :items="regions.map(function(c) { return { text: c.attributes.name, value: c.id } })"
                                  label="Region"
                                  v-model="editedEntry.region"
                                  solo
                              ></v-select>
                            </v-col>
                            <v-col cols="12" lg="4">
                              <v-select
                                  v-if="countries && editedEntry.region"
                                  :items="countries.filter(country => country.attributes.region_id === parseInt(editedEntry.region)).map(function(c) { return { text: c.attributes.name, value: c.id } })"
                                  label="Country"
                                  v-model="editedEntry.country"
                                  solo
                              ></v-select>
                            </v-col>
                            <v-col cols="12" lg="4">
                              <v-select
                                  v-if="states && editedEntry.country && states.filter(state => state.attributes.country_id == editedEntry.country).map(function(c) { return { text: c.attributes.name, value: c.id } }).length > 0"
                                  :items="states.filter(state => state.attributes.country_id == editedEntry.country).map(function(c) { return { text: c.attributes.name, value: c.id } })"
                                  label="State"
                                  v-model="editedEntry.state"
                                  solo
                              ></v-select>
                            </v-col>
                            <v-col cols="12" lg="4">
                              <v-select
                                  v-if="counties && editedEntry.state && counties.filter(county => county.attributes.state_id == editedEntry.state).map(function(c) { return { text: c.attributes.name, value: c.id } }).length > 0"
                                  :items="counties.filter(county => county.attributes.state_id == editedEntry.state).map(function(c) { return { text: c.attributes.name, value: c.id } })"
                                  label="County"
                                  v-model="editedEntry.county"
                                  solo
                              ></v-select>
                            </v-col>
                            <v-col cols="12" lg="4">
                              <v-select
                                  v-if="cities && editedEntry.county && cities.filter(city => city.attributes.county_id == editedEntry.county).map(function(c) { return { text: c.attributes.name, value: c.id } }).length > 0"
                                  :items="cities.filter(city => city.attributes.county_id == editedEntry.county).map(function(c) { return { text: c.attributes.name, value: c.id } })"
                                  label="City"
                                  v-model="editedEntry.city"
                                  solo
                              ></v-select>
                            </v-col>
                            <!-- Start date -->
                            <v-col cols="12">
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-menu
                                      ref="menu_edit_one"
                                      v-model="menu_edit_one"
                                      :close-on-content-click="false"
                                      :return-value.sync="editedEntry.timeRangeStart"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                          v-model="editedEntry.timeRangeStart"
                                          label="Select start date"
                                          readonly
                                          v-on="on"
                                          clearable
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        no-title
                                        scrollable
                                        :max="previousDate"
                                        >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" @click="menu_edit_one = false">Cancel</v-btn>
                                      <v-btn text color="primary" @click="$refs.menu_edit_one.save(date)">OK</v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-menu
                                      ref="menu_edit_two"
                                      v-model="menu_edit_two"
                                      :close-on-content-click="false"
                                      :return-value.sync="editedEntry.timeRangeEnd"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                          v-model="editedEntry.timeRangeEnd"
                                          label="Select end date"
                                          readonly
                                          v-on="on"
                                          clearable
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        :min="nextDate"
                                        v-model="anotherDate"
                                        no-title
                                        scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" @click="menu_edit_two = false">Cancel</v-btn>
                                      <v-btn text color="primary" @click="$refs.menu_edit_two.save(anotherDate)">OK</v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                            </v-col>
                            <!-- Keywords -->
                            <v-col cols="12">
                              <v-text-field
                                  label="Keywords"
                                  v-model="editedEntry.keywords"
                              ></v-text-field>
                            </v-col>
                            <!-- Special -->
                            <v-col cols="12" lg="4">
                              <v-text-field
                                  label="Special"
                                  v-model="editedEntry.special1"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4">
                              <v-text-field
                                  label="Special"
                                  v-model="editedEntry.special2"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4">
                              <v-text-field
                                  label="Special"
                                  v-model="editedEntry.special3"
                              ></v-text-field>
                            </v-col>

                            <!-- Lag -->
                            <v-col cols="12">
                              <v-text-field
                                      label="Lag"
                                      v-model="editedEntry.lag"
                              ></v-text-field>
                            </v-col>

                            <!-- File -->
                            <v-col cols="12" lg="6">
                              <v-switch
                                  @change="clearFiles"
                                  v-model="showFIle"
                                  class="ma-2"
                                  label="Provide file/Internal link"
                              >
                              </v-switch>
                            </v-col>
                            <template v-if="showFIle">
                              <v-col cols="12" lg="6">
                                <v-file-input
                                    v-model="editedEntry.file"
                                    placeholder="Upload your file"
                                    prepend-icon="mdi-paperclip"
                                >
                                  <template v-slot:selection="{ text }">
                                    <v-chip
                                        small
                                        label
                                        color="primary"
                                    >
                                      {{ text }}
                                    </v-chip>
                                  </template>
                                </v-file-input>
                              </v-col>
                            </template>
                            <template v-else>
                              <v-col cols="12" lg="6">
                                <v-text-field
                                    label="Internal link"
                                    v-model="editedEntry.internalLink"
                                ></v-text-field>
                              </v-col>
                            </template>

                          </v-row>
                        </v-form>
                      </v-container>
                      <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
                      <v-btn color="blue darken-1" text @click="createEntry()">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>

          </v-container>
        </template>




      </v-content>

      <v-footer app>
        <!-- -->
      </v-footer>
    </v-app>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  data: function () {
    return {
      state: 'main',
      valid: true,
      validCreate: true,
      dialog: false,
      date: new Date().toISOString().substr(0, 10),
      anotherDate: '',
      menu_edit_one: false,
      menu_edit_two: false,
      menu_first_date: false,
      menu_second_date: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: 'Category',
          sortable: true,
          value: 'attributes.category',
        },
        { text: 'Dataset name', value: 'attributes.dataset_name', sortable: true },
        { text: 'Link', value: 'attributes.link', sortable: true },
        { text: 'Spatial resolutions', value: 'attributes.spatial_resolution.relative_size', sortable: true },
        { text: 'Spatial coverage', value: 'attributes.spatial_coverage', sortable: true },
        { text: 'Temporal resolutions', value: 'attributes.temporal_resolution', sortable: false },
        { text: 'Temporal coverage', value: 'attributes.time_range_start', sortable: true },
        { text: 'Keywords', value: 'attributes.keywords', sortable: true },
        { text: 'File', value: 'attributes.internal_link', sortable: false },
        { text: 'Data type ', value: 'attributes.data_type.name', sortable: true },
        { text: 'Special 1', value: 'attributes.special1', sortable: true },
        { text: 'Special 2', value: 'attributes.special2', sortable: true },
        { text: 'Special 3', value: 'attributes.special3', sortable: true },
        { text: 'Lag', value: 'attributes.lag', sortable: true },
        { text: '',
          value: 'attributes.published',
          sortable: true },
      ],
      entries: [],
      categories: [],
      dataTypes: [],
      spatialResolutions: [],
      temporalResolutions: [{text: '0', value: '0 minutes'}, {text: '30 minutes', value: '30 minutes'},
                            {text: '1 hour', value: '1 hour'}, {text: '12 hours', value: '12 hours'},
                            {text: '1 day', value: '1 day'}, {text: '1 week', value: '1 week'},
                            {text: '1 month', value: '1 month'}, {text: '1 year', value: '1 year'}],
      regions: [],
      countries: [],
      states: [],
      counties: [],
      cities: [],
      filterCities: [],
      filterCategory: [] ,
      filterSpatialResolution: [0,0],
      filterTemporalResolution: [0, 9],
      filterTemporalCoverageStart: '',
      filterTemporalCoverageEnd: '',
      filterRegion: '',
      filterCountry: '',
      filterState: '',
      filterCounty: '',
      filterCity: '',
      filterKeywordFirst: '',
      filterKeywordSecond: '',
      filterKeywordThird: '',
      filterKeywordFourth: '',
      filterKeywordCondition: 'or',
      filterKeywordSecondCondition: 'or',
      filterKeywordBlockCondition: 'OR',
      editedIndex: -1,
      showFIle: false,
      editedEntry: {
        id: '',
        category: '',
        dataType: '',
        datasetName: '',
        spatialResolution: '',
        temporalResolution: '',
        timeRangeStart: '',
        timeRangeEnd: '',
        keywords: '',
        region: '',
        country: '',
        state: '',
        county: '',
        city: '',
        special1: '',
        special2: '',
        special3: '',
        internalLink: '',
        file: null,
        lag: '',
      },
      defaultEntry: {
        id: '',
        category: '',
        dataType: '',
        datasetName: '',
        spatialResolution: null,
        temporalResolution: '',
        timeRangeStart: '',
        timeRangeEnd: '',
        keywords: '',
        region: '',
        country: '',
        state: '',
        county: '',
        city: '',
        special1: '',
        special2: '',
        special3: '',
        internalLink: '',
        file: null,
        lag: '',
      },
      email: '',
      emailRules: [
        v => !!v || 'Cannot be empty',
        v => /.+@.+/.test(v) || 'Invalid email'
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Cannot be empty',
      ],
      blankRules: [
        v => !!v || 'Cannot be empty',
      ]
    }
  },
  methods: {
    async createEntry() {
      if (this.$refs.createForm.validate()) {
        let formData = this.formData();
        if (this.editedIndex === -1) {
          try {
            const response = await this.$http.post('/web/entries/', formData);
            this.close();
            this.entries.push(response.body.data)
          } catch (ex) {
            console.log(ex);
            this.$store.commit('updateMessage', { active: true, message: ex.body.data, message_type: 'error', message_position: 'modal' })
          }
        } else {
          try {
            const response = await this.$http.patch('/web/entries/' + this.editedEntry.id,
              formData);
            Object.assign(this.entries[this.editedIndex], response.body.data);
            this.close();
          } catch (ex) {
            this.$store.commit('updateMessage', { active: true, message: ex.body.data, message_type: 'error', message_position: 'modal' })
          }
        }
      }
    },
    initialLoad() {
      this.getCategories();
      this.getSpatialResolutions();
      this.getRegions();
      this.getEntries();
    },
    async getSpatialResolutions() {
      try {
        const response = await this.$http.get('/web/spatial_resolutions/');
        this.spatialResolutions = response.body.data;
      } catch(ex) {
        console.log(ex)
      }
    },
    async getEntries() {
      try {
        const response = await this.$http.get('/web/entries/');
        if (this.role) {
          this.entries = response.body.data;
        } else {
          this.entries = response.body.data.filter(e => e.attributes.published === true);
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    async getCategories() {
      try {
        const response = await this.$http.get('/web/categories/');
        this.categories = response.body.data;
      } catch (ex) {
        console.log(ex);
      }
    },
    async getRegions() {
      try {
        const response = await this.$http.get('/web/regions/');
        this.dataTypes = response.body.data.data_types.data;
        this.regions = response.body.data.regions.data;
        this.countries = response.body.data.countries.data;
        this.states = response.body.data.states.data;
        this.counties = response.body.data.counties.data;
      } catch (ex) {
        console.log(ex);
      }
    },
    async getFilterCities(id) {
      try {
        const response = await this.$http.get('/web/cities/', {params: { county_id: id }});
        this.filterCities = response.body.data;
      } catch (ex) {
        console.log(ex);
      }
    },
    async getCreateCities(id) {
      try {
        const response = await this.$http.get('/web/cities/', {params: { county_id: id }});
        this.cities = response.body.data;
      } catch (ex) {
        console.log(ex);
      }
    },
    async getFilteredEntries() {
      try {
        const response = await this.$http.get('/web/entries/',
          { params: { categories: this.filterCategory,
                      spatial_resolutions: this.getSpatialResolutionValues(),
                      temporal_resolutions: this.getTemporalResolutionValues(),
                      time_range_start: this.filterTemporalCoverageStart, time_range_end: this.filterTemporalCoverageEnd,
                      region: this.filterRegion, country: this.filterCountry, state: this.filterState,
                      county: this.filterCounty, city: this.filterCity,
                      keyword_condition: this.filterKeywordCondition,
                      keyword_second_condition: this.filterKeywordSecondCondition,
                      keyword_block_condition: this.filterKeywordBlockCondition,
                      keyword_first: this.filterKeywordFirst, keyword_second: this.filterKeywordSecond,
                      keyword_third: this.filterKeywordThird, keyword_fourth: this.filterKeywordFourth }});
        if (this.role) {
          this.entries = response.body.data;
        } else {
          this.entries = response.body.data.filter(e => e.attributes.published === true);
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    async clearFilters() {
      try {
        const response = await this.$http.get('/web/entries/');
        if (this.role) {
          this.entries = response.body.data;
        } else {
          this.entries = response.body.data.filter(e => e.attributes.published === true);
        }
        this.filterCategory = [];
        this.filterSpatialResolution = [0,0];
        this.filterTemporalResolution = [0, 9];
        this.filterTemporalCoverageStart = '';
        this.filterTemporalCoverageEnd = '';
        this.filterRegion = '';
        this.filterCountry = '';
        this.filterState = '';
        this.filterCounty = '';
        this.filterCity = '';
        this.filterKeywordFirst = '';
        this.filterKeywordSecond = '';
        this.filterKeywordThird = '';
        this.filterKeywordFourth = '';
        this.filterKeywordCondition = 'or';
        this.filterKeywordSecondCondition = 'or';
        this.filterKeywordBlockCondition = 'OR';
      } catch (ex) {
        console.log(ex);
      }
    },
    clearFiles() {
      this.editedEntry.file = null;
      this.editedEntry.internalLink = '';
    },
    async publishItem(item, publish) {
      const response = await this.$http.post('/web/publish/' + item.id, { publish: publish });
      try {
        let index = this.entries.indexOf(item);
        Object.assign(this.entries[index], response.body.data);
      } catch (ex) {
        console.log(ex);
      }
    },
    async destroyItem(item) {
      const response = await this.$http.delete('/web/entries/' + item.id);
      try {
        let index = this.entries.indexOf(item);
        this.entries.splice(index, 1);
        this.$store.commit('updateMessage', { active: true, message: 'Dataset destroyed' })
      } catch (ex) {
        console.log(ex);
      }
    },
    async copyItem(item) {
      const response = await this.$http.post('/web/copy/' + item.id);
      try {
        this.entries.push(response.body.data)
        this.$store.commit('updateMessage', { active: true, message: 'Dataset successfully copied' })
      } catch (ex) {
        console.log(ex);
      }
    },
    editItem (item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedEntry.id = item.id;
      if (item.attributes.category) {
        this.editedEntry.category = this.categories.find(c => c.name === item.attributes.category).id;
      }
      if (item.attributes.data_type_id) {
        this.editedEntry.dataType = item.attributes.data_type_id.toString();
      }
      if (item.attributes.spatial_resolution) {
        this.editedEntry.spatialResolution = this.spatialResolutions.find(r => parseInt(r.id) === item.attributes.spatial_resolution.id).id;
      }
      this.editedEntry.datasetName = item.attributes.dataset_name;
      if (item.attributes.temporal_resolution) {
        this.editedEntry.temporalResolution = item.attributes.temporal_resolution;
      }
      this.editedEntry.timeRangeStart = item.attributes.time_range_start;
      this.editedEntry.timeRangeEnd = item.attributes.time_range_end;
      this.editedEntry.keywords = item.attributes.keywords;
      if (item.attributes.region_id) {
        this.editedEntry.region = this.regions.find(r => parseInt(r.id) === item.attributes.region_id).id;
      }
      if(item.attributes.country_id) {
        this.editedEntry.country = this.countries.find(r => parseInt(r.id) === item.attributes.country_id).id;
      }
      if (item.attributes.state_id) {
        this.editedEntry.state = this.states.find(r => parseInt(r.id) === item.attributes.state_id).id;
      }
      if (item.attributes.county_id) {
        this.editedEntry.county = this.counties.find(r => parseInt(r.id) === item.attributes.county_id).id;
      }
      if (item.attributes.city_id) {
        this.editedEntry.city = this.cities.find(r => parseInt(r.id) === item.attributes.city_id).id;
      }
      if (item.attributes.link) {
        this.editedEntry.link = item.attributes.link;
      }
      if (item.attributes.internal_link) {
        this.editedEntry.internalLink = item.attributes.internal_link;
      }
      if (item.attributes.special1) {
        this.editedEntry.special1 = item.attributes.special1;
      }
      if (item.attributes.special2) {
        this.editedEntry.special2 = item.attributes.special2;
      }
      if (item.attributes.special3) {
        this.editedEntry.special3 = item.attributes.special3;
      }
      if (item.attributes.lag) {
        this.editedEntry.lag = item.attributes.lag;
      }

      this.dialog = true
    },
    close () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedEntry = Object.assign({}, this.defaultEntry);
        this.editedIndex = -1;
      })
    },
    login() {
      if (this.$refs.loginForm.validate()) {
        this.$http.post('/web/signin',
          { email: this.email, password: this.password } )
          .then(response => this.loginSuccessful(response))
          .catch((response) => console.log(response))
      }
    },
    loginSuccessful (response) {
      console.log(response);
      this.password = '';
      this.email = '',
      this.state = 'main';
      this.$store.commit('loginUser', { token: response.body.data.access,
        refresh_token: response.body.data.refresh, role: response.body.data.role });
      this.getEntries();
      this.headers[this.headers.length - 1].text = 'Actions';
    },
    logout() {
      this.$store.commit('logoutUser');
      this.getEntries();
      this.headers[this.headers.length - 1].text = '';
    },
    getSpatialResolutionValues() {
      let first = this.filterSpatialResolution[0];
      let last = this.filterSpatialResolution[1];
      console.log(first, last);
      return [this.spatialResolutions[parseInt(first)].attributes.relative_size,
              this.spatialResolutions[parseInt(last)].attributes.relative_size];
    },
    getTemporalResolutionValues() {
      let first = this.filterTemporalResolution[0];
      let last = this.filterTemporalResolution[1];
      console.log(first, last);
      return [this.temporalResolutions[parseInt(first)].value,
        this.temporalResolutions[parseInt(last)].value];
    },
    formData() {
      let formData = new FormData();
      formData.append('category_id', this.editedEntry.category);
      formData.append('data_type_id', this.editedEntry.dataType);
      formData.append('dataset_name', this.editedEntry.datasetName);
      formData.append('spatial_resolution_id', this.editedEntry.spatialResolution);
      if(this.editedEntry.temporalResolution) {
        formData.append('temporal_resolution', this.editedEntry.temporalResolution);
      } else {
        formData.append('temporal_resolution', 0);
      }
      formData.append('time_range_start', this.editedEntry.timeRangeStart);
      formData.append('time_range_end', this.editedEntry.timeRangeEnd);
      formData.append('link', this.editedEntry.link);
      formData.append('region_id', this.editedEntry.region);
      formData.append('country_id', this.editedEntry.country);
      formData.append('county_id', this.editedEntry.county);
      formData.append('state_id', this.editedEntry.state);
      formData.append('city_id', this.editedEntry.city);
      formData.append('keywords', this.editedEntry.keywords ? this.editedEntry.keywords : '');
      formData.append('special1', this.editedEntry.special1);
      formData.append('special2', this.editedEntry.special2);
      formData.append('special3', this.editedEntry.special3);
      if(this.editedEntry.lag) {
        formData.append('lag', this.editedEntry.lag);
      } else {
        formData.append('lag', 0);
      }
      formData.append('internal_link', this.editedEntry.internalLink);
      if (this.editedEntry.file) {
        formData.append('file', this.editedEntry.file, this.editedEntry.file.name);
      }
      return formData;
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Entry' : 'Edit Entry'
    },
    nextDate () {
      if (this.editedEntry.timeRangeStart) {
        let date = new Date (this.editedEntry.timeRangeStart);
        date.setDate(date.getDate() + 1);
        return date.toISOString().substr(0, 10);
      } else {
        return null;
      }
    },
    previousDate () {
      if (this.editedEntry.timeRangeEnd) {
        let date = new Date (this.editedEntry.timeRangeEnd);
        date.setDate(date.getDate() - 1);
        return date.toISOString().substr(0, 10);
      } else {
        return null;
      }
    },
    editedCounty() {
      return this.editedEntry.county;
    },
    ...mapGetters({ role: 'role' }),
    ...mapGetters({ show_message: 'show_message' }),
    ...mapGetters({ message: 'message' }),
    ...mapGetters({ message_position: 'message_position' }),
    ...mapGetters({ message_type: 'message_type' }),
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    async show_message(show_message) {
      if (!show_message) return;
      await this.$nextTick();
      setTimeout(() => {
        this.$store.commit('updateMessage', { active: false, message: '' })
      }, 5000);
    },
    filterCounty(val) {
      this.getFilterCities(val);
    },
    editedCounty(val) {
      this.getCreateCities(val);
    }
  },

  created() {
    this.initialLoad();
  },
  beforeCreate() {
    this.$store.commit('initialiseStore');
  }
}
</script>

<style scoped>

@import './../assets/styles/style.css';


p {
  font-size: 2em;
  text-align: center;
}

.link-block {
  cursor: pointer;
}



</style>

<style>

/*label.v-label.theme--light {
    color: white!important;
}*/

i.v-icon.notranslate.mdi.mdi-checkbox-blank-outline.theme--light {
    color: white!important;
}

/*.v-label.theme--light.v-label {
    color: white!important;
}*/

/*.theme--light.v-label {
    color: white!important;
}*/

.v-input.mt-0.checkbox-white.theme--light.v-input--selection-controls.v-input--checkbox .v-label.theme--light {
    color: white !important;
    font-size: 14px;
}

.v-input--selection-controls__input .v-input--selection-controls__ripple.primary--text {
    color: orange !important;
    caret-color: orange !important;
}

/*.theme--light.v-icon {
    color: #ff8f00cc !important;
}*/

.v-icon.notranslate.mdi.mdi-checkbox-marked.theme--light.primary--text {
    color: #ff8f00cc !important;
}

.text-start.sortable {
    white-space: nowrap;
}

.v-data-table-header {
    background-color: #b7b6b6;
    height: 80px;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: .85rem !important;
}

.v-data-table.elevation-1.theme--light {
    margin-top: -5px;
}

.v-slider__tick-label {
    font-size: 13px;
}

.v-slider--horizontal .v-slider__tick .v-slider__tick-label {
    top: 17px !important;
}

button.v-icon.notranslate.mr-2.v-icon--link.mdi.mdi-delete.theme--light {
    color: grey !important;
}

button.v-icon.notranslate.mr-2.v-icon--link.mdi.mdi-content-copy.theme--light {
    color: grey !important;
}

button.v-icon.notranslate.mr-2.v-icon--link.mdi.mdi-eye.theme--light {
    color: grey !important;
}

button.v-icon.notranslate.mr-2.v-icon--link.mdi.mdi-pencil.theme--light {
    color: grey !important;
}

table td {
    font-size: 12px !important;
    color: black !important;
}

th.text-start.sortable {
    height: 80px !important;
}

a {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
}

.v-application .mr-5 a {
    max-width: 100%;
}

.v-application--is-ltr .v-slider__tick:nth-child(odd) .v-slider__tick-label {
    
} 

.v-application--is-ltr .v-slider__tick:nth-child(even) .v-slider__tick-label {
    
    margin-top: -53px !important;
} 




@media only screen and (max-width : 767px) {


.v-slider__tick-label {
    font-size: 9px !important;
}

.v-application--is-ltr .v-slider__tick:nth-child(even) .v-slider__tick-label {
    
    margin-top: -43px !important;
} 



}

@media only screen and (max-width : 636px) {

thead.v-data-table-header.v-data-table-header-mobile {
    width: 150px !important;
}

.v-data-table-header-mobile__wrapper {
    width: 150px !important;
}

}


</style>
